<template>

  <b-modal ref="split-info-modal" hide-footer
    :title="title">

    <b-row class="p-8">
      <b-col style="font-size: 18px">{{ good_text }}</b-col>
    </b-row>

    <b-row class="p-8">
      <b-col style="font-size: 18px; color: red;">{{ bad_text }}</b-col>
    </b-row>

  </b-modal>
</template>

<script>
import axios from 'axios';
import 'vue-select/dist/vue-select.css';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: "split-info-popup",
  props: ['title','good_text','bad_text'],
  mixins: [ toasts ],

  computed: {
    ...mapGetters(['currentPeriodId', 'periods']),
  },
  async mounted() {

  },
  methods: {
    show() {
      this.$refs['split-info-modal'].show();
    },
  },
  data() {
    return {

    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_table.scss";

</style>
